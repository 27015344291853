import FormElementWrapper, { FormElementWrapperProps } from "./FormElementWrapper";

export interface CheckboxProps extends FormElementWrapperProps {
    label?: string;
    id?: string;
    placeholder?: string;
    value?: string | number;
    required?: boolean;
    checked?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

function Checkbox(props: CheckboxProps) {
  const { col, validationMessage, className, label, id, value, required, onChange, onBlur, checked } = props;
  return (
    <FormElementWrapper col={col} validationMessage={validationMessage} className={className}>
      <div className={`form-check ${validationMessage ? 'is-invalid' : ''}`}>
          <input
              type="checkbox"
              className={`form-check-input ${validationMessage ? 'is-invalid' : ''}`}
              id={id}
              value={value}
              checked={checked}
              required={required}
              onChange={onChange}
              onBlur={onBlur}
          />
          {label && (
            <label className="form-check-label" htmlFor={id}>
                {label}
            </label>
          )}
      </div>
    </FormElementWrapper>
  );
}

export default Checkbox;
