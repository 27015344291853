interface ValidationTextProps {
  value: string;
  message?: string;
  maxLength?: number;
  minLength?: number;
  required?: boolean;
  lettersOnly?: boolean;
}

interface ValidationEmailProps {
  value: string;
  message?: string;
  invalidEmailMessage?: string;
  required?: boolean;
}

interface ValidationCheckboxProps {
  value: boolean;
  message?: string;
  required?: boolean;
}

export const validateTextField = (props: ValidationTextProps) => {
  const { value, message = 'Please fill this field', maxLength, minLength, required = false, lettersOnly } = props;

  if (!value && required) {
    return message;
  }

  if (value.trim() === '') {
    return '';
  }

  if (lettersOnly && !(/^[a-zA-Z]+$/.test(value))) {
    return 'Letters only allowed'
  }

  if (maxLength && minLength && (value.length > maxLength || value.length < minLength)) {
    if (minLength === maxLength) {
      return `Must be ${minLength} characters`;
    }

    return `Must be between ${minLength} - ${maxLength} characters`;
  }

  if (maxLength && value.length > maxLength) {
      return `Field could not exceed ${maxLength} characters`;
  }

  if (minLength && value.length < minLength) {
      return `Field must have minimum characters of ${minLength}`;
  }

  return '';
};

export const validateEmailField = (props: ValidationEmailProps) => {
  const {
      value,
      required = false,
      message = 'Please fill your email address',
      invalidEmailMessage = 'Please fill with a valid email address'
  } = props;

  if (!value && required) {
      return message;
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return invalidEmailMessage;
  }

  return '';
};

export const validateCheckboxField = (props: ValidationCheckboxProps) => {
  const {
      value,
      required = false,
      message = 'Please fill this checkbox'
  } = props;

  if (!value && required) {
      return message;
  }

  return '';
};
