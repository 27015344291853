function Header() {
  return (
    <header>
        <div className="container">
            <div className="py-5 text-center">
            <img
                className="d-block mx-auto mb-4"
                src="/assets/images/logo.svg"
                alt=""
                width="72"
                height="57"
            />
            <h2>Update form</h2>
            <p className="lead">
                Example form with validations using bootstrap + react formik.
            </p>
            </div>
        </div>
    </header>
  );
}

export default Header;
