import { useField } from 'formik';
import Input, { InputProps } from '../Form/Input';
import { validateEmailField, validateTextField } from '../../utils/validationHelpers';

interface InputFormikProps extends InputProps {
  name: string;
  requiredMessage?: string;
  invalidEmailMessage?: string;
  lettersOnly?: boolean;
}

interface GetValidateValueProps {
  type?: string;
  required?: boolean;
  requiredMessage?: string;
  invalidEmailMessage?: string;
  lettersOnly?: boolean;
}

function getValidateValue({ type, required, requiredMessage, invalidEmailMessage, lettersOnly }: GetValidateValueProps) {
  switch (type) {
    case 'email': return (value: string) => validateEmailField({
      value,
      required,
      message: requiredMessage,
      invalidEmailMessage
    });
    default: return (value: string) => validateTextField({
      value,
      required,
      message: requiredMessage,
      lettersOnly
    });
  }
}

function InputFormik({ name, type, required, requiredMessage, invalidEmailMessage, lettersOnly,  ...props } : InputFormikProps) {
  const validate = getValidateValue({
    type,
    required,
    requiredMessage,
    invalidEmailMessage,
    lettersOnly
  });

  const [field, meta] = useField({
    name,
    type,
    validate
  });

  const validationMessage = meta.touched && meta.error ? meta.error : '';

  return (
    <Input
      validationMessage={validationMessage}
      type={type}
      required={required}
      {...field}
      {...props}
    />
  );
};

export default InputFormik;
