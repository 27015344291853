import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import MyAccountPage from "./pages/MyAccountPage";
import FormSubmittedPage from './pages/FormSubmittedPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MyAccountPage />} />
        <Route path="form-submitted" element={<FormSubmittedPage />} />
        <Route path="*" element={<MyAccountPage />}
      />
      </Routes>
    </Router>
  );
}

export default App;
