import { Link } from "react-router-dom";

export interface ButtonProps extends ButtonContentProps {
  type?: 'button' | 'submit';
  href?: string;
  disabled?: boolean;
  skin?: 'primary' | 'default' | 'secondary';
  size?: 'lg' | 'md' | 'sm';
  className?: string;
  fullWidth?: boolean;
  routerLink?: boolean;
}

interface ButtonContentProps {
  prefixIcon?: string;
  suffixIcon?: string;
  children?: React.ReactNode;
}

const ButtonContent = ({ prefixIcon, children, suffixIcon }: ButtonContentProps) => (
  <>
    {prefixIcon && (<i className={`btn-prefix-icon me-2 ${prefixIcon}`} />)}
    {children && (<span className="btn-content">{children}</span>)}
    {suffixIcon && (<i className={`btn-suffix-icon ms-2 ${suffixIcon}`} />)}
  </>
)

function Button({
  type = "button",
  href,
  children,
  disabled,
  skin = "primary",
  size = "md",
  fullWidth,
  className = '',
  prefixIcon,
  suffixIcon,
  routerLink
}: ButtonProps) {
  const btnClass = `${fullWidth ? 'w-100' : ''} btn btn-${skin} btn-${size} ${className}`;

  if (href) {
    if (routerLink) {
      return (
        <Link to="/" className={btnClass}>
          <ButtonContent prefixIcon={prefixIcon} suffixIcon={suffixIcon}>
            {children}
          </ButtonContent>
        </Link>
      )
    }
  
    return (
      <a className={btnClass} href={href}>
        <ButtonContent prefixIcon={prefixIcon} suffixIcon={suffixIcon}>
          {children}
        </ButtonContent>
      </a>
    )
  }
  return (
    <button className={btnClass} type={type} disabled={disabled}>
      <ButtonContent prefixIcon={prefixIcon} suffixIcon={suffixIcon}>
        {children}
      </ButtonContent>
    </button>
  );
}

export default Button;
