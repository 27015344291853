import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import CheckboxFormik from "../../components/Formik/CheckboxFormik";
import InputFormik from "../../components/Formik/InputFormik";
import SubmitButtonFormik from "../../components/Formik/SubmitButtonFormik";
import { User } from "../../types/user";

interface MyAccountProps {
  initialValues: User;
}

function MyAccount({ initialValues }: MyAccountProps) {
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          actions.setSubmitting(false);
          navigate("/form-submitted", { state: values })
        }, 1000);
      }}
    >
      <Form>
        <section className="my-account">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-7 col-lg-8">
                <h4 className="mb-3">My Account</h4>
                <div className="row g-3">
                  <InputFormik
                    label="First name"
                    name="firstName"
                    id="firstName"
                    col="6"
                    required
                    requiredMessage="Please fill your first name"
                    lettersOnly={true}
                  />
                  <InputFormik
                    label="Last Name (optional)"
                    name="last_name"
                    id="last_name"
                    col="6"
                    lettersOnly={true}
                  />
                  <InputFormik
                    label="Email Address"
                    name="emailAddress"
                    id="emailAddress"
                    type="email"
                    required
                    requiredMessage="Please fill your email"
                  />
                  <InputFormik
                    label="Date of Birth"
                    name="dob"
                    id="dob"
                    type="date"
                    col="5"
                  />
                </div>

                <hr className="my-4" />

                <CheckboxFormik
                  label="Subscribe newsletter"
                  name="subscribed"
                  id="subscribed"
                />

                <hr className="my-4" />

                <SubmitButtonFormik fullWidth={true}>
                  Submit
                </SubmitButtonFormik>
              </div>
            </div>
          </div>
        </section>
      </Form>
    </Formik>
  );
}

export default MyAccount;
