import { useFormikContext } from "formik";
import Button, { ButtonProps } from "../Button/Button";

type SubmitButtonFormikProps = ButtonProps;

function SubmitButtonFormik({ ...props }: SubmitButtonFormikProps) {
  const { isSubmitting } = useFormikContext();

  return (
    <Button {...props} disabled={isSubmitting} type="submit" />
  );
}

export default SubmitButtonFormik;
