import React from "react";

export interface FormElementWrapperProps {
    className?: string;
    children?: React.ReactNode;
    validationMessage?: string;
    col?: string;
}

function FormElementWrapper(props: FormElementWrapperProps) {
  const { col = '12', validationMessage, children, className = '' } = props;
  return (
    <div className={`col-sm-${col} ${className}`}>
        {children}
        {validationMessage && (
          <div className="invalid-feedback">
          {validationMessage}
          </div>
        )}
    </div>
  );
}

export default FormElementWrapper;
