import FormElementWrapper, { FormElementWrapperProps } from "./FormElementWrapper";

export interface InputProps extends FormElementWrapperProps {
    label?: string;
    id?: string;
    type?: string;
    placeholder?: string;
    value?: string | number;
    required?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

function Input(props: InputProps) {
  const { col, className, label, id, type = 'text', placeholder, value, required, validationMessage, onChange, onBlur } = props;

  return (
    <FormElementWrapper col={col} validationMessage={validationMessage} className={className}>
        {label && (
        <label htmlFor={id} className="form-label">
            {label}
        </label>
        )}
        <input
            type={type}
            className={`form-control ${validationMessage ? 'is-invalid' : ''}`}
            id={id}
            placeholder={placeholder}
            value={value}
            required={required}
            onChange={onChange}
            onBlur={onBlur}
        />
    </FormElementWrapper>
  );
}

export default Input;
