import { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import MyAccount from "../sections/MyAccount/MyAccount";
import { User } from "../types/user";
import { getUserData } from "../utils/services";

function MyAccountPage() {
  const [initialValues, setInitialValues] = useState<User | null>(null);

  useEffect(() => {
    getUserData()
      .then((response) => {
        setInitialValues(response.data);
      })
      .catch((response) => {
        console.error(`Error get Form data ${response}`);
      });
  }, []);

  return (
    <Layout>
      {initialValues && (
        <MyAccount initialValues={initialValues}/>
      )}
    </Layout>
  );
}

export default MyAccountPage;
