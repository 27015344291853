import { useField } from 'formik';
import { validateCheckboxField } from '../../utils/validationHelpers';
import Checkbox, { CheckboxProps } from '../Form/Checkbox';

interface CheckboxFormikProps extends CheckboxProps {
  name: string;
}

function CheckboxFormik({ name, required, ...props } : CheckboxFormikProps) {
  const [field, meta] = useField({
    name,
    type: "checkbox",
    validate: (value: boolean) => validateCheckboxField({ value, required })
  });

  const validationMessage = meta.touched && meta.error ? meta.error : '';

  return (
    <Checkbox
      validationMessage={validationMessage}
      required={required}
      {...field}
      {...props}
    />
  );
};

export default CheckboxFormik;
