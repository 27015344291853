import { useLocation } from "react-router-dom";
import Button from "../components/Button/Button";
import Layout from "../components/Layout/Layout";

function FormSubmittedPage() {
  const { state } = useLocation();

  return (
    <Layout>
      <main className="px-3">
        <div className="container">
          <h2>Your Form is Submitted </h2>
          <h3>Example data retreived:</h3>
          <hr />
          <pre>
            {JSON.stringify(state, null, 2)}
          </pre>
          <hr />
          <Button href="/" routerLink prefixIcon="bi-arrow-left">
            Back to Update Form
          </Button>
        </div>
      </main>
    </Layout>
  );
}

export default FormSubmittedPage;
