function Footer() {
  return (
    <footer className="my-5 pt-5 text-muted text-center text-small">
      <div className="container">
        <p className="mb-1">&copy; 2017–2022 Company Name</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="#privacy">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="#terms">Terms</a>
          </li>
          <li className="list-inline-item">
            <a href="#support">Support</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
